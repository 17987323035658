import { template as template_c4f1d12f7e574764957e572e0ac82b67 } from "@ember/template-compiler";
const FKControlMenuContainer = template_c4f1d12f7e574764957e572e0ac82b67(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
