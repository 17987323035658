import { template as template_85d35bb908764d3393c6184ecf3041e8 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_85d35bb908764d3393c6184ecf3041e8(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
