import { template as template_f8a4b0bcd76e4a65a53ee9e1e9865085 } from "@ember/template-compiler";
const FKLabel = template_f8a4b0bcd76e4a65a53ee9e1e9865085(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
