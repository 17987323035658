import { template as template_5e3f6b0a581145d08a8507fdaf47a8e2 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_5e3f6b0a581145d08a8507fdaf47a8e2(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
